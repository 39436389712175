import React, { Component } from 'react';
import './Acknowledgements.css';

class Acknowledgements extends Component {
    render() {
        return (
            <div className="acknowlegements">
                <div>This website was built by Ellen Huang using Create React App</div>
                <div>and is generously hosted by GitHub Pages</div>
            </div>
        )
    }
}

export default Acknowledgements
